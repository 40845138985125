import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Button, { ButtonVariant } from "../../Button";
import { CTA_ID } from "../../../utils/analytics/constants";

interface ResourceCardProps {
  resource: any;
}

const ResourceCard: React.FC<ResourceCardProps> = props => {
  const { resource } = props;

  return (
    <>
      <div className="w-full max-w-[300px] h-96 relative rounded-xl overflow-hidden shadow-2xl sm:max-w-[auto] sm:w-1/2 md:h-80 md:w-full lg:h-96 ">
        <GatsbyImage
          className="h-full min-w-full absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2"
          image={resource?.image?.gatsbyImageData}
          alt="preview"
        />
        <div className="absolute opacity-0 w-full h-full bg-gradient-to-br from-blue-1 to-purple-1 transition duration-200 ease-in"></div>
      </div>
      <div className=" items-center flex flex-col flex-grow mb-4 sm:w-1/2 md:items-start md:w-full">
        <p className="text-base mt-8 mb-2 font-semibold text-indigo-1">
          {resource?.subtitle}
        </p>
        <p className="text-center text-2xl my-3 font-semibold flex flex-grow md:text-left">
          {resource?.title}
        </p>
        {resource?.category && (
          <Link
            to={`/resources/${resource?.category[0].toLowerCase()}/${
              resource?.slug
            }/`}
            className="h-full relative pb-8 group"
          >
            <Button
              id={CTA_ID.resourcesGridAssets.id}
              variant={ButtonVariant.primary}
            >Get The Report</Button>
          </Link>
        )}
      </div>
    </>
  );
};

export default ResourceCard;
