import React from "react";
import SEO from "../components/Seo";
import Layout from "../containers/Layout";
import EmbeddedCta from "../components/Blog/EmbeddedCta";
import ResourcesLayout from "../containers/ResourcesLayout";
import Section from "../containers/Section";
import { graphql } from "gatsby";
import CustomBreadcrumb from "../components/CustomBreadcrumb";

// TODO: pass more info to match Figma Designs
const Resources = ({ pageContext }) => {
  return (
    <Layout inlineSwitcher hideNav>
      <SEO
        title={"eCommerce Advertising Blog"}
        description={
          "Perpetua's blog for eCommerce advertising news, insights tips and content for sellers, brands and agencies looking to grow their business."
        }
      />
      <div className="max-w-8xl mx-auto mt-40 px-6 sm:px-12 lg:px-4">
        <CustomBreadcrumb crumbs={pageContext?.breadcrumb?.crumbs} />
      </div>
      <ResourcesLayout />
      <Section mobileWide className="px-0 sm:py-12">
        <EmbeddedCta
          fullWidth
          buttonText="Sign Up"
          title="Insights to help grow profit"
          textFieldPlaceholder="Email address"
          successText="Thanks for signing up! We promise only the best updates and growth rocket fuel coming your way."
        />
      </Section>
    </Layout>
  );
};

export default Resources;

export const data = graphql`
  query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
