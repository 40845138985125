import React from "react";
import Header, { HeaderVariant } from "../../Header";
import Download from "../../../images/download.svg";
import ResourceCard from "../../Blog/ResourceCard";
import cn from "classnames";
import { getSrc } from "gatsby-plugin-image";

interface ResourceListProps {
  title?: string;
  CTA?: any | null;
  ctaPosition?: any;
  ctaSize?: string;
  resources: any;
}

const ResourcesList: React.FC<ResourceListProps> = props => {
  const { title, resources } = props;

  return (
    <div className="max-w-wide mx-auto">
      <div className="w-full flex flex-col md:flex-row justify-between items-center mb-8">
        <Header
          className="pt-3 lg:pt-0 w-auto flex flex-col md:flex-row justify-center items-center"
          variant={HeaderVariant.h2}
        >
          <Download aria-hidden={true} />
          <span className="text-4xl lg:text-5xl font-bold flex mt-6 md:mt-0 ml-3">
            {title}
          </span>
        </Header>
      </div>
      <ul className="grid grid-cols-1 md:grid-cols-3 lg:pb-6 justify-center relative sm:-mx-4">
        {resources?.map((resource, i) => {
          const image =
            resource?.image?.gatsbyImageData || getSrc(resource?.image);
          if (image) {
            return (
              <li
                key={resource.id}
                className={cn(
                  "col-span-1 w-full mx-auto flex items-center justify-center xs:max-w-[auto] px-4",
                  i % 2 == 0
                    ? "flex-col sm:flex-row md:block"
                    : "flex-col sm:flex sm:flex-row-reverse md:block"
                )}
              >
                <ResourceCard resource={resource} />
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
};

export default ResourcesList;
