import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import AnchorNavigation from "../../components/Blog/AnchorNavigation";
import GradientSection from "../GradientSection";
import cn from "classnames";
import ResourcesList from "../../components/Blog/ResourcesList";
import FeaturedResource from "../../components/Blog/FeaturedResource";
import NavigationBar from "../../components/NavigationBar";
import { NavigationBarVariant } from "../../components/NavigationBar/models";

const ResourcesLayout = () => {
  const data = useStaticQuery(graphql`
    query {
      featured: allContentfulResources(
        limit: 1
        sort: { fields: createdAt, order: DESC }
        filter: { node_locale: { eq: "en-US" } }
      ) {
        nodes {
          category
          id
          title
          subtitle
          bodyDescription
          slug
          image {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      reports: allContentfulResources(
        sort: { fields: createdAt, order: DESC }
        filter: { category: { eq: "Reports" }, node_locale: { eq: "en-US" } }
      ) {
        nodes {
          category
          id
          title
          subtitle
          slug
          image {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      guides: allContentfulResources(
        sort: { fields: createdAt, order: DESC }
        filter: { category: { eq: "Guides" }, node_locale: { eq: "en-US" } }
      ) {
        nodes {
          category
          id
          title
          subtitle
          slug
          image {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  `);

  const featuredResource = data?.featured?.nodes[0];
  const reports = data?.reports?.nodes;
  const guides = data?.guides?.nodes;

  const navigation = [
    {
      link: "/resources#guides",
      text: "Guides",
      textColor: "text-black",
      textColorHover: "hover:text-black"
    },
    {
      link: "/resources#reports",
      text: "Reports",
      textColor: "text-black",
      textColorHover: "hover:text-black"
    }
  ];

  const sections: any = [
    {
      title: "Guides",
      anchor: "guides",
      ctaPosition: 4,
      ctaSize: "medium",
      gradientSectionClasses: "pt-16 lg:pt-22",
      list: guides
    },
    {
      title: "Reports",
      anchor: "reports",
      gradientSectionClasses: "pt-16 lg:pt-22",
      list: reports
    }
  ];

  const [elRefs, setElRefs] = React.useState([]);
  const [activeSection, setActiveSection] = React.useState<string>("");

  function isVisible(ele, height) {
    const { top, bottom } = ele?.current?.getBoundingClientRect();
    return (top > 0 || bottom > 0) && top < height;
  }

  React.useEffect(() => {
    setElRefs(elRefs =>
      sections?.map((_, i) => elRefs[i] || React.createRef())
    );
  }, [sections.length]);

  React.useEffect(() => {
    window?.addEventListener("scroll", () => {
      const currentScroll = window?.pageYOffset;
      elRefs?.map((item, i) => {
        if (item && item.current) {
          let divTop = item.current.offsetTop - item.current.offsetHeight / 3;
          let divBottom =
            item.current.offsetTop +
            item.current.offsetHeight +
            item.current.offsetHeight / 3;
          if (
            isVisible(item, window?.innerHeight) &&
            divTop < currentScroll &&
            currentScroll < divBottom
          ) {
            setActiveSection(item.current.id);
          }
        }
      });
    });
  }, [elRefs]);

  return (
    <>
      <NavigationBar variant={NavigationBarVariant.blog} blogNav={navigation} />
      <FeaturedResource
        heroTitle="Download"
        color="text-gray-4-dark"
        resource={featuredResource}
      />
      <AnchorNavigation
        items={navigation}
        active={activeSection}
        spacing="left"
      />
      {sections?.map((section, i) => {
        return (
          <div key={i} id={section.anchor} ref={elRefs[i]}>
            <GradientSection
              className={cn(
                section.gradientSectionClasses,
                "pt-12 lg:pb-0 lg:pt-22"
              )}
            >
              <div className="mx-auto">
                <ResourcesList
                  title={section.title}
                  ctaPosition={section.ctaPosition}
                  CTA={section.cta}
                  resources={section.list}
                />
              </div>
            </GradientSection>
          </div>
        );
      })}
    </>
  );
};

export default ResourcesLayout;
