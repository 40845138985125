import React from "react";
import Header, { HeaderVariant } from "../../Header";
import Download from "../../../images/download.svg";
import Section from "../../../containers/Section";
import Button, { ButtonSize, ButtonVariant } from "../../Button";
import Img from "gatsby-image";
import { Link, navigate } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { CTA_ID } from "../../../utils/analytics/constants";

interface FeaturedResourceProps {
  heroTitle?: string;
  CTA?: any | null;
  ctaPosition?: any;
  color?: string;
  ctaSize?: string;
  setActiveVideo?: any;
  resource?: any;
}

const FeaturedResource: React.FC<FeaturedResourceProps> = props => {
  const { heroTitle, resource } = props;
  return (
    <Section className="pt-8 pb-6">
      <div className="py-18 bg-gradient-to-t from-indigo-3-light rounded-2xl">
        <div className="w-full md:w-[80%] max-w-wide mx-auto grid grid-cols-3 items-center">
          <div className="flex flex-col items-center md:items-start col-span-3 md:col-span-2">
            <Header
              className="md:mb-9 text-sm md:text-sm"
              variant={HeaderVariant.h1}
            >
              <span
                className={`font-medium text-2xl text-transparent mb-0 bg-gradient-to-r from-indigo-1 to-purple-4 bg-clip-text`}
              >
                Resources
              </span>
            </Header>
            <Header
              className="flex flex-col items-center mb-8 md:flex-row lg:mb-16"
              variant={HeaderVariant.h2}
            >
              <Download className="max-w-[30px] mr-4" />
              <span className="text-indigo-1 text-4xl flex font-semibold lg:font-bold lg:text-5xl">
                {heroTitle}
              </span>
            </Header>
            <Header
              className="flex items-center mb-8 text-center md:text-left md:mr-8 lg:mb-16"
              variant={HeaderVariant.h4}
            >
              {resource?.bodyDescription}
            </Header>
            <Button
              id={CTA_ID.resourcesGridAssets.id}
              size={ButtonSize.large}
              onClick={() =>
                navigate(
                  `/resources/${resource.category[0].toLowerCase()}/${resource.slug
                  }`
                )
              }
              variant={ButtonVariant.primary}
            >
              Get The Report
            </Button>
          </div>
          <div className="col-span-3 h-full w-full mx-auto md:col-span-1 flex justify-center items-center mt-14 md:mt-0">
            <GatsbyImage
              alt=""
              className="shadow-2xl max-w-[216px] rounded-2xl overflow-hidden w-full sm:max-w-[380px]"
              image={resource?.image?.gatsbyImageData}
            />
          </div>
        </div>
      </div>
    </Section>
  );
};

export default FeaturedResource;
